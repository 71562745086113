.container {
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    width: 100%;
    gap: 10px;
    border-bottom: var(--border);
    background-color: #fff;
    box-shadow: var(--shadow);

    svg {
        padding: 20px 5px;
    }

    img {
        width: 60px;
        border-radius: 10px;
    }
    
    .title {
        flex: 1;
        h3 {
            font-size: 1rem;
        }
        h4 {
            font-size: 0.8rem;
            font-weight: 300;
        }
        h5 {
            font-size: 0.8rem;
            font-weight: 300;
        }
    }
    .open {
        text-align: center;
        width: unset;
        background-color: #0e6fa8;
        margin: 0;
        color: #fff;
        border-radius: 10px;
        font-size: 0.8rem;
        padding: 7px;
    }
    &:first-of-type {
        margin-bottom: 20px;
    }
}